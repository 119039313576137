import React, { useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import moment from 'moment';

import Formatter from '../../utils/formatter';
import useApi from '../../hooks/useApi';
import { useUserData } from '../../hooks/useUserData';
import { isEmpty } from 'lodash-es';

import './styles.less';

enum DropdownOptions {
  PDF = 'PDF',
  JSON = 'JSON',
  HTML = 'HTML',
}

const CDE_PRINT_ENCOUNTER_DATA_ID = 'print-encounter-action-handler';
const CDE_PREPARE_ENCOUNTER_DATA_HTML_ID = 'prepare-encounter-data-html-handler';

const DownloadApppointmentButton = ({
  patientId,
  appointmentId,
  isLoading,
  className,
  isForPrintEncounterAction,
}: {
  patientId?: string;
  appointmentId?: number;
  isLoading?: boolean;
  className?: string;
  isForPrintEncounterAction?: boolean;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const user = useUserData();

  const [patient, setPatient] = useState<any>({});
  const [appointment, setAppointment] = useState<any>({});
  const [orders, setOrders] = useState<any>({});
  const [htmlContent, setHtmlContent] = useState<string>('');

  const {
    id,
    firstName,
    lastName,
    middleName,
    dob,
    gender,
    externalId,
    memberId,
    payerId,
    ehrInsurance,
    homePhoneNumber,
    phoneNumber,
    location,
    pcp,
  } = patient;
  const { id: ehrId, provider, startDate, metadata, assessment } = appointment;

  const metadataFormatted: any = {};
  metadata?.forEach(({ key, value }) => (metadataFormatted[key] = value));

  const api = useApi();

  const fetchAppointmentDetails = async ({ patientId, appointmentId }) => {
    const [patient, appointment, ordersData] = await Promise.all([
      api.getPatient({ id: patientId }),
      api.getAppointment({ id: appointmentId }),
      api.getOrders({ patientId, appointmentId }),
    ]);
    setPatient(patient);
    setAppointment(appointment);
    setOrders(ordersData.data);
  };

  const getAppointmentForPrintEncounter = async (locator: string) => {
    const cdeButton = document.querySelector(`[data-id="${locator}"]`);
    const cdeData = JSON.parse(cdeButton?.getAttribute('value') || '{}');

    const { patientId: cdePatientId, encounterId: cdeEncounterId } = cdeData;

    if (!cdePatientId || !cdeEncounterId) {
      throw new Error('Missing data to download encounter PDF');
    }

    await fetchAppointmentDetails({ patientId: cdePatientId, appointmentId: cdeEncounterId });
  };

  const handlePrintEncounterAction = async () => {
    await getAppointmentForPrintEncounter(CDE_PRINT_ENCOUNTER_DATA_ID);
    savePDF();
  };

  const handlePrepareEncounterDataHtmlAction = async () => {
    setHtmlContent('');
    await getAppointmentForPrintEncounter(CDE_PREPARE_ENCOUNTER_DATA_HTML_ID);
    const htmlContent = prepareHtml();
    setHtmlContent(htmlContent);
    console.log('vika HTML content prepared:', htmlContent);
  };

  const savePDF = () => {
    //@ts-ignore
    html2canvas(contentRef.current, { useCORS: true })
      .then(() => {
        const doc = new jsPDF();

        //@ts-ignore
        doc.html(contentRef.current, {
          callback: (doc) => {
            doc.save(`vim_encounter.pdf`);
          },
          margin: [10, 10, 10, 10],
          autoPaging: 'slice',
          x: 10,
          y: 10,
          width: 190,
          windowWidth: 675,
        });
      })
      .catch((error) => {
        console.error('Error generating PDF:', error);
      });
  };

  const saveJson = async () => {
    await fetchAppointmentDetails({ patientId, appointmentId });

    const data = {
      encounter: {
        ehrId,
        date: moment(startDate).format('YYYY-MM-DD'),
      },
      provider: {
        ehrId: provider.id,
        npi: provider.npi,
        credentials: provider?.suffix,
        specialty: provider?.specialty,
        demographics: {
          firstName: provider.firstName,
          lastName: provider.lastName,
          middleName: provider?.middleName,
        },
      },
      patient: {
        ehrId: id,
        contact_info: {
          email: patient?.email,
          homePhoneNumber: homePhoneNumber?.number,
          mobilePhoneNumber: phoneNumber?.number,
        },
        demographics: {
          firstName,
          lastName,
          middleName,
          dateOfBirth: dob,
          gender,
          address: {
            fullAddress: `${location?.addressLine1}, ${location?.city}, ${location?.state} ${location?.zip}`,
            address1: location?.addressLine1,
            address2: '',
            city: location?.city,
            state: location.state,
            zipCode: location?.zip,
          },
        },
        insurance: {
          memberId,
          ehrInsurance,
        },
        pcp: {
          ehrId: pcp?.id,
          npi: pcp?.npi,
          demographics: {
            firstName: pcp?.firstName,
            lastName: pcp?.lastName,
            middleName: pcp?.middleName,
          },
          specialty: [
            {
              description: pcp?.specialty,
            },
          ],
        },
      },
    };
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(data))}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = `vim_encounter.json`;

    link.click();
  };

  const prepareHtml = (): string => {
    if (contentRef.current) {
      const htmlContent = contentRef.current.outerHTML;
      return htmlContent;
    }
    return '';
  };

  const saveHtml = async () => {
    await fetchAppointmentDetails({ patientId, appointmentId });

    if (contentRef.current) {
      const htmlContent = contentRef.current.outerHTML;
      const blob = new Blob([htmlContent], { type: 'text/html' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = 'vim_encounter.html';
      link.click();
      setTimeout(() => URL.revokeObjectURL(url), 100);
    }
  };

  return (
    <>
      {!isEmpty(patient) && !isEmpty(appointment) && (
        <div className="patient-html-data" ref={contentRef}>
          <h4 className="title">
            {`${lastName.toUpperCase()}, ${firstName}`}: <strong>DOB: </strong>
            {moment(dob).format('MM/DD/YYYY')} ({Formatter.getAgeByDOB(dob)} yo {gender[0]} Acc No.{' '}
            {externalId || payerId || id}) <strong>DOS: </strong>
            {moment(startDate).format('MM/DD/YYYY')}
          </h4>
          <div className="patient-details-section">
            <div>
              <strong>Patient: </strong>
              {`${lastName}, ${firstName}`}
              <br />
              <strong>Account Number: </strong>
              {externalId || payerId || id}
              <br />
              <strong>DOB: </strong>
              {moment(dob).format('MM/DD/YYYY')} <strong>Age: </strong>
              {Formatter.getAgeByDOB(dob)} Y <strong>Sex: </strong>
              {gender}
              <br />
              <strong>Phone: </strong>
              {phoneNumber?.number}
              <br />
              <strong>Address: </strong>
              {!isEmpty(location)
                ? `${location?.addressLine1}, ${location?.city}, ${location?.state} ${location?.zip}`
                : null}
              <br />
              <strong>Pcp: </strong>
              {`${pcp?.firstName} ${pcp?.lastName}`}
            </div>
            <div>
              <strong>Provider: </strong>
              {`${provider.firstName} ${provider.lastName}, ${provider?.suffix}`}
              <br />
              <strong>Date: </strong>
              {moment(startDate).format('MM/DD/YYYY')}
            </div>
          </div>

          <div className="appointment-details-section">
            <span className="section-title">Subjective:</span>
            <br />
            <span className="section-sub-title">General Notes:</span>
            <p className="section-details">{metadataFormatted?.subjective_general_notes}</p>
            <span className="section-sub-title">Chief Complaints:</span>
            <p className="section-details">{metadataFormatted?.subjective_chief_complaint}</p>
          </div>

          <div className="appointment-details-section">
            <span className="section-title">Objective:</span>
            <br />
            <span className="section-sub-title">General Notes:</span>
            <p className="section-details">{metadataFormatted?.objective_general_notes}</p>
            <span className="section-sub-title">Physical Examination:</span>
            <p className="section-details">{metadataFormatted?.objective_physical_examination}</p>
          </div>

          <div className="appointment-details-section">
            <span className="section-title">Assessments:</span>
            <br />
            <span className="section-sub-title">General Notes:</span>
            <p className="section-details">{metadataFormatted?.assessment_notes}</p>
            <span className="section-sub-title">Assessment:</span>
            <p className="section-details">
              {assessment
                .filter((item) => item.taxonomy.type == 'icd')
                .map(({ taxonomy }, index) => {
                  return (
                    <>
                      {`${index + 1}. ${taxonomy?.description} - ${taxonomy?.label}`}
                      <br />
                    </>
                  );
                })}
            </p>
          </div>

          <div className="appointment-details-section">
            <span className="section-title">Plan:</span>
            <br />
            <span className="section-sub-title">General Notes:</span>
            <p className="section-details">{metadataFormatted?.plans_notes}</p>
            <span className="section-sub-title">Orders:</span>
            <p className="section-details">
              {!isEmpty(orders) &&
                orders.map(({ cpt, icd, referTo }, index) => {
                  return (
                    <>
                      <strong>{`${index + 1}. ${icd?.[0]}`}</strong>
                      <br />
                      {referTo?.type}: {cpt?.[0]}
                      <br />
                      <strong>Procedure Codes: </strong>
                      {cpt}
                      <br />
                    </>
                  );
                })}
            </p>
          </div>

          <div className="signature-details">
            <strong>
              Electronically signed by {user.firstName} {user.lastName} on{' '}
              {moment().format('MM/DD/YYYY')} at {moment().format('HH:MM')}
            </strong>
            <br />
            <strong>Sign off status: Completed</strong>
          </div>
        </div>
      )}
      <div className={`download-encounter-dropdown ${className}`}>
        {isForPrintEncounterAction && (
          <>
            <button
              data-id={CDE_PRINT_ENCOUNTER_DATA_ID}
              onClick={handlePrintEncounterAction}
            ></button>
            <button
              data-id={CDE_PREPARE_ENCOUNTER_DATA_HTML_ID}
              data-html-content={htmlContent}
              data-is-html-content-prepared={Boolean(htmlContent)}
              onClick={handlePrepareEncounterDataHtmlAction}
            ></button>
          </>
        )}
        <DropdownButton
          bsPrefix="btn btn-primary-v2"
          title="Download"
          id="dropdown-basic"
          disabled={isLoading}
        >
          <Dropdown.Item
            id="download-pdf-btn"
            className="dropdown-menu-item"
            key={DropdownOptions.PDF}
            onClick={async () => {
              await fetchAppointmentDetails({ patientId, appointmentId });
              savePDF();
            }}
          >
            {DropdownOptions.PDF}
          </Dropdown.Item>
          <Dropdown.Item
            id="download-json-btn"
            className="dropdown-menu-item"
            key={DropdownOptions.JSON}
            onClick={saveJson}
          >
            {DropdownOptions.JSON}
          </Dropdown.Item>
          <Dropdown.Item
            id="download-html-btn"
            className="dropdown-menu-item"
            key={DropdownOptions.HTML}
            onClick={saveHtml}
          >
            {DropdownOptions.HTML}
          </Dropdown.Item>
        </DropdownButton>
      </div>
    </>
  );
};

export default DownloadApppointmentButton;
